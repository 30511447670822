import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark, faPaperPlane, faHouse } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom";
import Contact from './Contact';
import Modal from './Modal';
import { ToastContainer, toast } from 'react-toastify';
import PrIcon from "../../assets/img/PrIcon.png"

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);

  const navigation = [
    { name: 'Solutions', to: 'solutions' },
    // { name: 'Pricing', to: 'pricing' },
    { name: 'About', to: 'about' },
  ]

  const handeNav = (e) => {
    e.preventDefault();
    let switchTo = isOpen === false ? true : false;
    setIsOpen(switchTo);
    console.log(switchTo);
  };

  const handleContactUs = () => {
    let toggle = contactOpen === false ? true : false;
    setContactOpen(toggle);
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  };

  const responseMessage = (success, message) => {
    if (success) {
      toast.success(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    console.log(contactOpen);
  }, [contactOpen])

  return (
    <div className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="-ml-2 mr-2 flex items-center md:hidden">
              {/* Mobile menu button */}
              <button type="button" onClick={(e) => { handeNav(e) }} className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="absolute -inset-0.5" />
                {isOpen && <FontAwesomeIcon icon={faXmark} />}
                {!isOpen && <FontAwesomeIcon icon={faBars} />}
              </button>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <NavLink
                to="/"
              >
                <img width="32" src={PrIcon} alt="PrIcon" />
              </NavLink>
            </div>
            <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              {navigation.map((item) => (
                <NavLink
                  to={item.to}
                  key={item.name}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'rounded-md px-3 py-2 text-sm font-medium'
                    )
                  }
                  aria-current={({ isActive }) =>
                    isActive ? 'page' : undefined
                  }
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
                onClick={(e) => { handleContactUs(e) }}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpen === true && <div className="md:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          {navigation.map((item) => (
            <NavLink
              to={item.to}
              key={item.name}
              className={({ isActive }) =>
                classNames(
                  isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'block rounded-md px-3 py-2 text-base font-medium'
                )
              }
              aria-current={({ isActive }) =>
                isActive ? 'page' : undefined
              }
              onClick={() => { setIsOpen(false) }}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>}

      {contactOpen && <Modal
        open={contactOpen}
        setOpen={handleContactUs}
      >
        <Contact
          responseMessage={responseMessage}
          close={handleContactUs}
        />
      </Modal>}

      <ToastContainer toastStyle={{ backgroundColor: "#111827" }} />
    </div>
  )
}