import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/img/PhillipsRenewables.svg"

const Splash = () => {
  return (
    <>
      <div className="w-full h-full bg-hero bg-no-repeat bg-cover bg-center bg-fixed">
        <div className="mx-auto max-w-2xl py-24 sm:py-32 lg:py-32 backdrop-grayscale-100">
          <div className="text-center">
            <div className="text-4xl font-bold tracking-tight text-gray-200 sm:text-6xl">
              <Logo />
            </div>
            <div className="mb-8 flex justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-300 ring-1 ring-gray-200/10">
                Proud Partner of <b className="text-green-500">G. Phillips Heating</b>
              </div>
            </div>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Powering Your Tomorrow, Sustainably Today: Phillips Renewables - Your Partner in Solar, Heat, and Electric Solutions.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              {/* <NavLink
                to="pricing"
                className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Get started
              </NavLink> */}
              <NavLink
                to="solutions"
                className="text-sm font-semibold leading-6 text-green-500"
              >
                Learn more <span aria-hidden="true">→</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Splash;