import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSolarPanel, faFire, faFileCircleCheck, faBolt, faBatteryFull, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

// className="absolute left-1 top-1 h-5 w-5 text-green-500"

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

const values = [
  {
    name: 'Be world-class.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: <FontAwesomeIcon className="absolute left-1 top-1 h-5 w-5 text-green-500" aria-hidden="true" icon={faSolarPanel} />,
  },
  {
    name: 'Take responsibility.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: <FontAwesomeIcon className="absolute left-1 top-1 h-5 w-5 text-green-500" aria-hidden="true" icon={faSolarPanel} />,
  },
  {
    name: 'Be supportive.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus voluptas blanditiis et.',
    icon: <FontAwesomeIcon className="absolute left-1 top-1 h-5 w-5 text-green-500" aria-hidden="true" icon={faSolarPanel} />,
  },
  {
    name: 'Always learning.',
    description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
    icon: <FontAwesomeIcon className="absolute left-1 top-1 h-5 w-5 text-green-500" aria-hidden="true" icon={faSolarPanel} />,
  },
  {
    name: 'Share everything you know.',
    description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
    icon: <FontAwesomeIcon className="absolute left-1 top-1 h-5 w-5 text-green-500" aria-hidden="true" icon={faSolarPanel} />,
  },
  {
    name: 'Enjoy downtime.',
    description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
    icon: <FontAwesomeIcon className="absolute left-1 top-1 h-5 w-5 text-green-500" aria-hidden="true" icon={faSolarPanel} />,
  },
]
const team = [
  {
    name: 'Leslie Alexander',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    location: 'Toronto, Canada',
  },
  // More people...
]
const benefits = [
  'Competitive salaries',
  'Flexible work hours',
  '30 days of paid vacation',
  'Annual team retreats',
  'Benefits for you and your family',
  'A great work environment',
]

export default function About() {
  return (
    <div>
      <main className="relative isolate">
        {/* Background */}
        {/* <div
          className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
            style={{
              clipPath:
                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
            }}
          />
        </div> */}

        {/* Header section */}
        <div className="px-6 pt-14 lg:px-8">
          <div className="mx-auto max-w-2xl pt-24 text-center sm:pt-40">
            <h2 className="text-4xl font-bold tracking-tight text-green-500 sm:text-6xl">Your Partner in Sustainable Energy Solutions</h2>
          </div>
        </div>

        {/* Content section */}
        <div className="mx-auto mt-20 mb-20 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
              <div>
                <p>

                  Phillips Renewables, a family-run enterprise and sister company to G. Phillips Heating, boasts over 40 years of collective experience in the heating industry. Our specialization lies in delivering comprehensive solutions such as Solar PV, Air Source Heat Pumps, Electric Boilers, Heat Loss Surveys, and more to discerning homeowners and businesses.                </p>
                <p className="mt-8">
                  What distinguishes us from our competitors is our personalised service and dependable expertise. As a family-run business, we are positioned to cultivate a robust customer connection and offer a standard of service that larger national companies may lack. Our commitment is rooted in understanding our customers' needs and delivering bespoke solutions.                </p>
              </div>
              <div>
                <p>
                  Our primary focus is offering consultation and installation services for domestic housing and businesses. While the majority of our work is concentrated in Northumberland and Tyne & Wear, we are also open to expanding our services to cater to clients in other locations as needed.
                </p>
                <p className="mt-8">
                  Under the leadership of Jack Phillips, our Director, the team is guided by a wealth of knowledge and experience within the heating industry. With his direction, we hold confidence in our capacity to deliver high-quality products and services that contribute to fostering a more sustainable future.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Values section */}
        {/* <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our values</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste
              dolor cupiditate blanditiis.
            </p>
          </div>
          <dl className="mx-auto mt-16 mb-20 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            {values.map((value) => (
              <div key={value.name} className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  {value.icon}
                  {value.name}
                </dt>{' '}
                <dd className="inline">{value.description}</dd>
              </div>
            ))}
          </dl>
        </div> */}
      </main>
    </div>
  )
}