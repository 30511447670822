export default function RoundButton(props) {
  return (
    <>
      {props.size === 'small' && <button
        type="button"
        onClick={props.onClick}
        className="rounde p-1 text-gray-500 hover:text-gray-400 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {props.children}
      </button>}
      {props.size === 'medium' && <button
        type="button"
        onClick={props.onClick}
        className="rounde p-1.5 text-gray-500 hover:text-gray-400 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {props.children}
      </button>}
      {props.size === 'large' && <button
        type="button"
        onClick={props.onClick}
        className="rounde p-2 text-gray-500 hover:text-gray-400 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {props.children}
      </button>}
    </>
  )
}
