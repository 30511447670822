import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSolarPanel, faFire, faFileCircleCheck, faBolt, faBatteryFull } from '@fortawesome/free-solid-svg-icons'

const features = [
  {
    name: 'Solar PV',
    description:
      'Solar PV, or photovoltaics, is a technology that harnesses sunlight to generate electricity. Photovoltaic cells, typically made from semiconductor materials like silicon, convert sunlight into direct current (DC) electricity. These cells are grouped together to form solar panels, which can be installed on rooftops, open fields, or integrated into various structures.',
    icon: <FontAwesomeIcon className="h-6 w-6 text-white" aria-hidden="true" icon={faSolarPanel} />,
  },
  {
    name: 'Air Source Heat Pumps',
    description:
      'An air source heat pump (ASHP) is a heating and cooling system that extracts heat from the outdoor air to warm the interior of a building during colder periods and, conversely, removes heat from indoor air to cool the space during warmer months. ASHPs operate on the principles of thermodynamics, using a refrigerant cycle to transfer heat between the indoor and outdoor environments.',
    icon: <FontAwesomeIcon className="h-6 w-6 text-white" aria-hidden="true" icon={faFire} />,
  },
  {
    name: 'Electric Boilers',
    description:
      'An electric boiler is a heating system that uses electricity as the primary source of energy to generate heat for space heating and domestic hot water. Unlike traditional boilers that burn fossil fuels, such as gas or oil, electric boilers convert electrical energy directly into heat.',
    icon: <FontAwesomeIcon className="h-6 w-6 text-white" aria-hidden="true" icon={faBolt} />,
  },
  {
    name: 'Heat Loss Surveys',
    description:
      'A heat loss survey, also known as a thermal or energy audit, is a comprehensive assessment conducted to identify and quantify the areas in a building where heat is escaping. The primary goal of a heat loss survey is to evaluate the thermal performance of a structure, uncover potential energy inefficiencies, and suggest improvements to enhance energy conservation and reduce heating costs.',
    icon: <FontAwesomeIcon className="h-6 w-6 text-white" aria-hidden="true" icon={faFileCircleCheck} />,
  },
  {
    name: 'Battery Storage',
    description:
      'Battery storage refers to the technology of storing electrical energy in batteries for later use. These systems play a crucial role in modern energy infrastructure, enabling the capture and storage of surplus electricity generated during periods of low demand or high renewable energy production.',
    icon: <FontAwesomeIcon className="h-6 w-6 text-white" aria-hidden="true" icon={faBatteryFull} />,
  },
]

export default function Solutions() {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <h2 className="text-3xl font-bold tracking-tight text-green-500 sm:text-4xl">
            Sustainable Power Solutions
          </h2>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt className="text-base font-semibold leading-7 text-green-600">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    {feature.icon}
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 text-base leading-7 text-gray-300">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
