// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grecaptcha-badge{visibility:hidden}.spinner{animation:rotator 1.4s linear infinite;position:relative;top:-1px}@keyframes rotator{0%{transform:rotate(0deg)}100%{transform:rotate(270deg)}}.path{stroke-dasharray:187;stroke-dashoffset:0;transform-origin:center;animation:dash 1.4s ease-in-out infinite}@keyframes dash{0%{stroke-dashoffset:187}50%{stroke-dashoffset:46.75;transform:rotate(135deg)}100%{stroke-dashoffset:187;transform:rotate(450deg)}}`, "",{"version":3,"sources":["webpack://./src/assets/scss/style.scss"],"names":[],"mappings":"AAOA,kBAAA,iBAAA,CAEA,SACE,sCAAA,CACA,iBAAA,CACA,QAAA,CAGF,mBACE,GACE,sBAAA,CAEF,KACE,wBAAA,CAAA,CAGJ,MACE,oBAAA,CACA,mBAAA,CACA,uBAAA,CACA,wCAAA,CAGF,gBACE,GACE,qBAAA,CAEF,IACE,uBAAA,CACA,wBAAA,CAEF,KACE,qBAAA,CACA,wBAAA,CAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,300&display=swap');\n\n@import \"variables/colours\";\n\n// base styles\n@import \"base/base\";\n\n.grecaptcha-badge { visibility: hidden; }\n\n.spinner {\n  animation: rotator 1.4s linear infinite;\n  position: relative;\n  top: -1px;\n}\n\n@keyframes rotator {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(270deg);\n  }\n}\n.path {\n  stroke-dasharray: 187;\n  stroke-dashoffset: 0;\n  transform-origin: center;\n  animation: dash 1.4s ease-in-out infinite;\n}\n\n@keyframes dash {\n  0% {\n    stroke-dashoffset: 187;\n  }\n  50% {\n    stroke-dashoffset: 46.75;\n    transform: rotate(135deg);\n  }\n  100% {\n    stroke-dashoffset: 187;\n    transform: rotate(450deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
