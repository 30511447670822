import { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import RoundButton from './RoundButton'
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

export default function Contact(props) {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY), []);

  const handleInputChange = (e) => {
    e.preventDefault();
    setValues(prev => {
      // Object.assign would also work
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const token = await recaptchaRef.current.executeAsync();

      let formData = values;
      formData['g-recaptcha-response'] = token;

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_RSVP_ID, formData
      );

      setLoading(false);
      props.responseMessage(true, 'Your message has been sent successfully. Thank you.');
      props.close();
    } catch (error) {
      console.log(error);
      props.responseMessage(true, 'Oops! Something went wrong when trying to send your message.');
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(values);
  }, [values])

  return (
    <div>
      <div className="w-full flex justify-end">
        <RoundButton
          size="small"
          onClick={props.close}
        >
          <FontAwesomeIcon className="h-4 w-6" icon={faXmark} />
        </RoundButton>
      </div>
      <div className="isolate bg-gray-800 px-6 py-8 lg:px-8">
        <form onSubmit={handleSubmit} className="mx-auto max-w-xl">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-400">
                First name
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={values?.firstName ?? ''}
                  onChange={handleInputChange}
                  className="bg-gray-700 block w-full rounded-md border-0 px-3.5 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-semibold leading-6 text-gray-400">
                Last name
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={values?.lastName ?? ''}
                  onChange={handleInputChange}
                  className="bg-gray-700 block w-full rounded-md border-0 px-3.5 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-400">
                Company <span className="text-xs">&#91;optional&#93;</span>
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="company"
                  id="company"
                  value={values?.company ?? ''}
                  onChange={handleInputChange}
                  className="bg-gray-700 block w-full rounded-md border-0 px-3.5 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-400">
                Email
              </label>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={values?.email ?? ''}
                  onChange={handleInputChange}
                  className="bg-gray-700 block w-full rounded-md border-0 px-3.5 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-gray-400">
                Phone Number <span className="text-xs">&#91;optional&#93;</span>
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={values?.phone ?? ''}
                  onChange={handleInputChange}
                  className="bg-gray-700 block w-full rounded-md border-0 px-3.5 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-400">
                Message
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  className="bg-gray-700 block w-full rounded-md border-0 px-3.5 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  value={values?.message ?? ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            />
          </div>
          <div className="w-full flex justify-center">
            <button type="submit" className="mx-auto inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              {loading && <>
                <svg className="spinner -ml-1 mr-2 h-5 w-5" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle className="path" fill="none" stroke="white" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
                Sending...
              </>}
              {!loading && <>Send</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}