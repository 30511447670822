import { Routes, Route } from "react-router-dom";
import Navigation from "./pages/components/Navigation";
import NoPage from "./pages/NoPage";
import Footer from "./pages/components/Footer";
import Splash from './pages/Splash';
import Solutions from './pages/Solutions';
import Pricing from './pages/Pricing';
import About from './pages/About';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-700 to-gray-800">
      <Navigation />
      <Routes>
        <Route index element={<Splash />} />
        <Route path="solutions" element={<Solutions />} />
        {/* <Route path="pricing" element={<Pricing />} /> */}
        <Route path="about" element={<About />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
